import React from "react";
import "./login.css";

const beURL = import.meta.env.VITE_BEURL;

const Login = () => {
  const loginwithgoogle = () => {
    window.open(beURL + "/auth/google", "_self");
  };

  return (
    <>
      <div className="login-page">
        <div className="form">
          <h1>Login</h1>

          <div className="content">
            <p>
              <strong>~~~ What this website does ~~~</strong>
            </p>
            <p>
              When you log in using your Gmail account linked to your YouTube
              channel, you will be able to view your YouTube subscriptions and
              all your videos.
            </p>
            <p>
              On the subscriptions page, you can click on any channel to open
              the respective YouTube channel in a new tab.
            </p>
            <p>
              On the video detail page, you will see all your videos sorted by
              upload date. You can also upload new videos to your channel and
              delete any video from the video detail page.
            </p>
            <p>
              Please review the Privacy Policy before logging in. By logging in,
              you acknowledge that you have accepted our Privacy Policy and its
              terms and conditions.
            </p>
          </div>

          <button className="login-with-google-btn" onClick={loginwithgoogle}>
            Login With Google
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
