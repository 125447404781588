import { Link, useMatch, useResolvedPath } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./navbar.css";
import Logo from "../../public/mytube.svg";

const beURL = import.meta.env.VITE_BEURL;

export default function Navbar() {
  const [userdata, setUserdata] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);

  const getUser = async () => {
    try {
      if (!loggedIn) {
        const response = await axios.get(beURL + "/login/success", {
          withCredentials: true,
        });
        if (response.data.user) {
          setUserdata(response.data.user);
          setLoggedIn(true); // User is logged in
        }
      }
    } catch (error) {
      console.log("Error occurred in Navbar: ", error);
    }
  };

  // logout
  const logout = async () => {
    window.open(beURL + "/logout", "_self");
  };

  useEffect(() => {
    getUser();
  }, [loggedIn]);

  return (
    <nav className="nav">
      {/* <h1>Welcome to </h1> */}
      <img src={Logo} alt="Logo" />
      {Object?.keys(userdata)?.length > 0 ? (
        <>
          <h1 style={{ color: "gold", fontWeight: "bold" }}>
            {userdata.displayName}
          </h1>
          {userdata.image && <img src={userdata.image} className="profile" alt="User" />}
        </>
      ) : (
        ""
      )}
      <ul>
        {/* Always display Privacy Policy */}
        <CustomLink
          to="#"
          onClick={() => {
            window.open("/privacy-policy.html", "_blank"); // Open Privacy Policy in a new tab
          }}
        >
          Privacy Policy
        </CustomLink>

        {/* Conditional Links: Display login/logout based on user's login state */}
        {Object?.keys(userdata)?.length > 0 ? (
          <>
            <CustomLink to="/dashboard">Dashboard</CustomLink>
            <CustomLink to="" onClick={logout}>
              Logout
            </CustomLink>
          </>
        ) : (
          <CustomLink to="/login">Login</CustomLink>
        )}
      </ul>
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
