import React from "react";
import "./datatable.css";

const DataTable = ({ data }) => {
  console.log("Data table's data:", data); // Log data for debugging

  return (
    <div className="table-container">
      <table>
        <thead className="table-header">
          <tr>
            <th>Channel Name</th> 
            <th>Channel ID</th> 
          </tr>
        </thead>
        <tbody className="table-body">
          {data && data.length > 0 ? (
            data.map((subscription, index) => (
              <tr key={index}>
                <td style={{color:"black"}} className="channel-name">{subscription.channelName}</td> {/* Channel Name */}
                <td className="channel-id">
                  {/* Create a clickable link using the channelId */}
                  <a 
                    href={`https://www.youtube.com/channel/${subscription.channelId}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {subscription.channelId} {/* Display the channelId */}
                  </a>
                </td> {/* Channel ID */}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No data available</td> {/* Message when no data is available */}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
