import React, { useState, useEffect } from "react";
import "./videopane.css"; // Import your CSS for styling
import axios from "axios";

function VideoPane() {
  const [videos, setVideos] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [likedVideos, setLikedVideos] = useState(new Set()); // Track liked videos by ID
  const [popupMessage, setPopupMessage] = useState(""); // Track popup message
  const beURL = import.meta.env.VITE_BEURL; // Backend URL

  // State to hold the video IDs fetched from the backend
  const [videoIds, setVideoIds] = useState([]);

  // Fetch video IDs from the backend
  useEffect(() => {
    const fetchVideoIds = async () => {
      try {
        const response = await axios.get(beURL + "/api/recent-videos"); // Fetch recent video IDs from the backend
        console.log("Fetched Video IDs:", response.data); // Log the video IDs
        setVideoIds(response.data); // Set the videoIds in the state
      } catch (error) {
        console.error("Error fetching video IDs:", error);
      }
    };

    fetchVideoIds(); // Fetch the video IDs first
  }, []); // Empty dependency array ensures this is run only once on component mount

  // Fetch video details from the backend once videoIds are set
  useEffect(() => {
    const fetchVideos = async () => {
      if (videoIds.length > 0) {
        try {
          const response = await fetch(
            beURL + `/api/videos?videoIds=${videoIds.join(",")}` // Pass the videoIds array as a query parameter
          );
          const videoData = await response.json();
          console.log("Fetched Video Data:", videoData); // Log the fetched video data
          setVideos(videoData);
        } catch (error) {
          console.error("Error fetching videos:", error);
        }
      }
    };

    fetchVideos(); // Call fetchVideos after videoIds are set
  }, [videoIds]); // Re-run the effect when videoIds are updated

  // Check if the user is logged in
  useEffect(() => {
    const checkUserLogin = async () => {
      try {
        const response = await axios.get(beURL + "/login/success", {
          withCredentials: true,
        });
        if (response.data.user) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking user login:", error);
      }
    };

    checkUserLogin();
  }, []);

  // Handle click on like button
  const handleLikeClick = async (videoId) => {
    if (!isLoggedIn) {
      alert("Please login to like the video.");
      return;
    }

    try {
      const response = await axios.post(
        beURL + "/api/like-video",
        { videoId: videoId },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data.success) {
        setLikedVideos((prevLikedVideos) => new Set(prevLikedVideos.add(videoId)));
        setPopupMessage("You have liked this video!");
        setTimeout(() => {
          setPopupMessage(""); // Hide popup after 3 seconds
        }, 3000);
      } else {
        alert("Failed to like the video.");
      }
    } catch (error) {
      console.log("Error liking the video:", error);
    }
  };

  // Function to handle opening the video in a new tab
  const handleOpenVideo = (videoId) => {
    const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
    window.open(videoUrl, "_blank"); // Open in a new tab
  };

  return (
    <div>
      {/* Display popup message if the user successfully liked a video */}
      {popupMessage && (
        <div className="popup">
          <p>{popupMessage}</p>
        </div>
      )}

      {videos.length > 0 ? (
        <div className="right-pane">
          <h2>Recent Videos</h2>
          <ul>
            {videos.map((video) => (
              <li key={video.id}>
                <img
                  src={video.snippet.thumbnails.medium.url}
                  alt={video.snippet.title}
                  className="video-thumbnail"
                  onClick={() => handleOpenVideo(video.id)} // Open video in a new tab on click
                />
                <div className="video-info">
                  <span
                    className="video-title"
                    onClick={() => handleOpenVideo(video.id)} // Open video in a new tab on title click
                  >
                    {video.snippet.title}
                  </span>
                  <span className="channel-name">{video.snippet.channelTitle}</span>
                  <span className="video-stats">
                    {video.statistics.viewCount} views | {video.statistics.likeCount} likes
                  </span>
                 {false && <div className="like-dislike-container">
                    <button
                      className={`like-btn ${likedVideos.has(video.id) ? "liked" : ""}`}
                      onClick={() => handleLikeClick(video.id)}
                      disabled={likedVideos.has(video.id)} // Disable button if already liked
                    >
                      {likedVideos.has(video.id) ? "Liked" : "Like"}
                    </button>
                  </div>}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p></p> // Display message if no videos are loaded
      )}
    </div>
  );
}

export default VideoPane;
